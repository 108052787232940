<template>
  <div class="jobfair">
    <div class="job-content">
      <div class="banner">
        <div class="banner-img"></div>
      </div>
      <!-- <div class="home-select">
        <div class="select-contain">
          <el-form>
            <el-form-item>
              <el-col :span="20">
                <el-input
                  v-model="selfrom.KW"
                  placeholder="搜索职位、公司"
                ></el-input>
              </el-col>
              <el-col :span="4">
                <el-button
                  type="primary"
                  @click="
                    selfrom.pageNumber = 1;
                    GetList();
                  "
                  >搜索</el-button
                >
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div> -->
      <div class="jobfair-activity">
        <div class="activity-contain">
          <div class="title">专场招聘会信息</div>
          <div class="activity-cell">
            <div
              class="activity-item"
              @click="goto('/jobfairdetail', { id: activitydata.id })"
            >
              <div class="activity-img">
                <img :src="setjphlogo(activitydata.cZbCover)" alt="" />
              </div>
              <div class="activity-body">
                <div class="activity-name">
                  <span class="gyx" v-if="activitydata.cZphlb == '公益性'"
                    >公益性</span
                  >
                  <b>【{{ activitydata.cZhuti }}】</b>{{ activitydata.czph }}
                </div>
                <div class="activity-data">
                  活动时间：{{ stringToDates(activitydata.cZphDate) }}
                </div>
                <div class="activity-address">
                  活动地址：{{ activitydata.cAddress }}
                </div>
              </div>
              <div class="activity-btn">
                <el-button type="primary" v-if="user.userType != 1"
                  >点击报名</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-company">
        <div class="company-content">
          <div class="company-title">参会企业</div>
          <div class="company-cell">
            <div
              class="company-item"
              v-for="(cell, j) in companylist"
              :key="j"
              @mouseover="enter(cell)"
              @mouseleave="leave"
            >
              <div class="company-logo">
                <img :src="setcompanylogo(cell.coM_LOGO, 'deflogo')" alt="" />
              </div>
              <div class="company-name">{{ cell.cComName }}</div>
              <div class="company-num">
                <b>{{ cell.counts }}</b
                >个热招岗位
              </div>
              <div
                class="company-post"
                :class="{ hoveractive: isActive && cell.iComid == num }"
              >
                <div
                  class="post-item"
                  v-for="(cont, n) in analysisjob(cell.info)"
                  :key="n"
                >
                  <div class="post-name">{{ cont.postname }}</div>
                  <div class="post-salary">{{ cont.salary }}</div>
                </div>
                <div class="post-more">
                  <el-button
                    size="mini"
                    round
                    @click="
                      goto('/company', {
                        id: cell.iComid,
                        zid: activitydata.id,
                      })
                    "
                    >查看更多>></el-button
                  >
                </div>
              </div>
            </div>
            <el-empty
              :image-size="200"
              v-if="companylist.length < 1"
            ></el-empty>
          </div>
        </div>
      </div>

      <div style="text-align: center; margin-top: 10px">
        <el-pagination
          hide-on-single-page
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="selfrom.pageNumber"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="selfrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Getjobfairlist,
  specialjobfairlist,
  jobfairdetailclist,
} from "@/api/home";
import utils from "@/assets/js/utils";
import { isnull } from "@/assets/js/index";
import global from "@/assets/js/globalconfig";
export default {
  data() {
    return {
      defimgurl: global.baseimgurl,
      selfrom: {
        KW: "",
        pageNumber: 1,
        pageSize: 10,
      },
      input: "",
      user: {},
      val: "",
      total: 0,
      activitydata: {},
      isActive: false,
      cdata: [],
      companylist: [],
      scenedata: [
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
        {
          src: require("@/assets/img/sc1.jpg"),
          title: "促进高校毕业生就业大型公益活动",
          type: "市县联动",
        },
      ],
    };
  },
  created() {
    if (!isnull(localStorage.getItem("userinfo"))) {
      this.user = JSON.parse(localStorage.getItem("userinfo"));
    }
    this.specialjobfairlist();
  },
  methods: {
    setjphlogo(url) {
      return isnull(url)
        ? require("@/assets/img/activity-1.jpg")
        : this.defimgurl + url;
    },
    setcompanylogo(url, data) {
      return isnull(url)
        ? require(`@/assets/img/logos.png`)
        : this.defimgurl + url;
    },
    goto(url, data) {
      this.resolvewin(url, data, 1);
    },
    specialjobfairlist() {
      var _this = this;
      _this.selfrom.showLoadType = ".activity-cell";
      specialjobfairlist(_this.selfrom).then((res) => {
        if (res.success) {
          if (res.data.rows.length > 0) {
            _this.activitydata = res.data.rows[0];
            _this.jobfairdetailclist(res.data.rows[0].id);
          }
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    jobfairdetailclist(id) {
      var _this = this;
      jobfairdetailclist({ id: id }).then((res) => {
        if (res.success) {
          _this.companylist = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    stringToDates(data) {
      return utils.stringToDate(data);
    },
    enter(cell) {
      this.isActive = true;
      this.num = cell.iComid;
    },

    leave() {
      this.isActive = false;
    },
    analysisjob(datas) {
      var data = [];
      datas.split(",").forEach((item) => {
        if (data.length < 3) {
          var a = item.split("|");
          var sp = a[1].split("-");
          data.push({
            postname: a[0],
            salary: this.setsalary(sp[0], sp[1]),
          });
        }
      });
      return data;
    },
    setsalary(a, b) {
      var data = [];
      if (!isnull(a)) {
        if (a < 100 && a > 0) {
          data.push(a + "k");
        } else if (a >= 1000) {
          data.push(a / 1000 + "k");
        } else {
          data.push(a);
        }
      }
      if (!isnull(b)) {
        if (b < 100 && b > 0) {
          data.push(b + "k");
        } else if (b >= 1000) {
          data.push(b / 1000 + "k");
        } else {
          data.push(b);
        }
      }
      if (data.length === 0) {
        return "面议";
      } else {
        return data.join("-");
      }
    },
    handleSizeChange(val) {
      this.selfrom.pageNumber = val;
      this.selfrom.pageNumber = 1;
    },
    handleCurrentChange(val) {
      this.selfrom.pageNumber = val;
    },
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 300px;
  padding: 0;
  margin: 0px;
  margin-bottom: 20px;
  position: relative;
  background: url(../../assets/img/33.png) top center no-repeat;
  // .banner-img {
  //   width: 1200px;
  //   height: 300px;
  //   margin: 0px auto;
  //   padding: 0;
  //   background: url(../../assets/img/33.png) top center no-repeat;
  // }
}
.home-select {
  width: 960px;
  margin: 0px auto;
  padding: 24px;
  .select-contain {
    .el-form {
      width: 100%;
      background: #fff;
      .el-select ::v-deep {
        .el-input {
          padding-top: 4px;
          padding-bottom: 4px;
          .el-input__inner {
            border: none;
            border-radius: 0;
            font-size: 14px;
          }
          .el-input__inner::placeholder {
            color: #333;
          }
        }
      }
      .el-input ::v-deep {
        padding-top: 4px;
        padding-bottom: 4px;
        .el-input__inner {
          border: none;
          border-radius: 0;
          border-left: 1px solid #eee;
        }
      }
      .el-button {
        width: 100%;
        text-align: center;
        border-radius: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
      }
    }
    .hot-word {
      text-align: left;
      font-size: 16px;
      color: #787878;
      a {
        padding: 0px 12px;
        color: #409eff;
        text-decoration: none;
      }
    }
  }
}
.jobfair-activity {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  .activity-contain {
    .title {
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
    }
    .activity-cell {
      .activity-item {
        position: relative;
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-top: 1px solid #eee;
        .activity-img {
          width: 200px;
          height: 110px;
          border-radius: 8px;
          display: flex;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
        .activity-body {
          flex: 1;
          width: 100%;
          text-align: left;
          padding-left: 20px;
          position: relative;
          .activity-name {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 20px;
            b {
              color: #ff5722;
            }
          }
          .activity-data {
            font-size: 14px;
            color: #787878;
            margin-bottom: 12px;
          }
          .activity-address {
            font-size: 14px;
            color: #787878;
          }
        }
        .activity-btn {
          position: absolute;
          bottom: 16px;
          right: 0;
        }
      }
      .activity-item:first-child {
        padding-top: 0;
        border-top: none;
      }
    }
  }
}
.jobfair-company {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .company-content {
    .company-title {
      font-size: 18px;
      color: #333;
      margin-bottom: 16px;
    }
    .company-cell {
      .el-carousel ::v-deep {
        .el-carousel__container {
          .el-carousel__item {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .company-item {
              width: 275px;
              padding: 12px 0;
              background: #f2f2f2;
              margin-bottom: 15px;
              position: relative;
              overflow: hidden;
              .company-logo {
                height: 50px;
                text-align: center;
                img {
                  height: 50px;
                }
              }
              .company-name {
                font-size: 14px;
                line-height: 32px;
                color: #333;
                text-align: center;
              }
              .company-num {
                padding: 8px 0px;
                border-radius: 2px;
                border: 1px solid #eee;
                font-size: 12px;
                background: #fff;
                width: 180px;
                margin: 0px auto;
                text-align: center;
                b {
                  color: #ff0000;
                }
              }
              .company-post {
                position: absolute;
                padding: 16px;
                left: 0;
                right: 0;
                top: 140px;
                height: 110px;
                background: rgba($color: #000000, $alpha: 0.6);
                transition: all 0.5s;
                .post-item {
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  margin-bottom: 10px;
                  .post-name {
                    color: #fff;
                    font-size: 12px;
                  }
                  .post-salary {
                    font-size: 12px;
                    color: #ff0000;
                  }
                }
                .post-more {
                  text-align: center;
                }
              }
              .hoveractive {
                top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.jobfair-position {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .position-content {
    .position-title {
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
    }
    .position-cell {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      .position-item {
        width: 243px;
        padding: 12px 16px;
        position: relative;
        background: #f2f2f2;
        margin-bottom: 16px;
        .position-hd {
          font-size: 16px;
          color: #409eff;
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;
          align-items: center;
          .post-salary {
            font-size: 16px;
            color: #ff6600;
            font-weight: bold;
          }
        }
        .position-bd {
          font-size: 12px;
          color: #787878;
          margin-bottom: 12px;
          text-align: left;
          span {
            padding: 0 4px;
            border-right: 1px solid #eee;
          }
          span:first-child {
            padding-left: 0;
          }
          span:last-child {
            border: none;
          }
        }
        .position-card {
          display: flex;
          align-items: center;
          text-align: left;
          padding-top: 12px;
          border-top: 1px dashed #ddd;
          .card-img {
            width: 32px;
            line-height: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            background: #fff;
            border-radius: 2px;
            img {
              width: 100%;
            }
          }
          .card-body {
            flex: 1;
            .card-name {
              font-size: 14px;
              color: #333;
            }
            .card-posthot {
              font-size: 12px;
              color: #787878;
              b {
                color: #409eff;
              }
            }
          }
        }
      }
    }
    .position-more {
      width: 200px;
      height: 32px;
      line-height: 32px;
      color: #fff;
      font-size: 14px;
      margin: 0px auto;
      text-align: center;
      a {
        text-decoration: none;
        color: #fff;
        background: #409eff;
        transition: all 0.5s;
        display: block;
      }
      a:hover {
        background: rgba($color: #409eff, $alpha: 0.7);
      }
    }
  }
}
.jobfair-scene {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  .scene-content {
    .title {
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
    }
    .scene-cell {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .scene-item {
        width: 375px;
        height: 210px;
        position: relative;
        z-index: 1;
        .scene-img {
          width: 375px;
          height: 210px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            transition: all 0.3s;
          }
          img:hover {
            transform: scale(1.2);
          }
        }
        .scene-title {
          position: absolute;
          padding: 8px 16px;
          background: rgba($color: #000000, $alpha: 0.5);
          font-size: 14px;
          color: #fff;
          left: 0;
          right: 0;
          bottom: 0;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
          -moz-text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.gyx {
  padding: 4px 10px;

  border-radius: 4px;
  background: #ff7630;
  color: white;
  font-size: 15px;

  margin-right: 10px;
}
.home-company {
  width: 1170px;
  margin: 0px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  .company-content {
    .company-title {
      font-size: 18px;
      color: #333;
      margin-bottom: 16px;
    }
    .company-cell {
      .company-item {
        width: 275px;
        height: 135px;
        // padding: 12px 0;
        background: #f2f2f2;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
        .company-logo {
          height: 50px;
          padding-top: 12px;
          text-align: center;
          img {
            height: 50px;
          }
        }
        .company-name {
          font-size: 14px;
          line-height: 32px;
          color: #333;
          text-align: center;
          width: 70%;
          margin: 0 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .company-num {
          padding: 8px 0px;
          border-radius: 2px;
          border: 1px solid #eee;
          font-size: 12px;
          background: #fff;
          width: 180px;
          margin: 0px auto;
          text-align: center;
          b {
            color: #ff0000;
          }
        }
        .company-post {
          position: absolute;
          padding: 16px;
          left: 0;
          right: 0;
          top: 140px;
          height: 110px;
          background: rgba($color: #000000, $alpha: 0.6);
          transition: all 0.5s;
          .post-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .post-name {
              color: #fff;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 190px;
            }
            .post-salary {
              font-size: 12px;
              color: #ff0000;
              max-width: 50px;
            }
          }
          .post-more {
            text-align: center;
          }
        }
        .hoveractive {
          top: 0;
        }
      }
    }
  }
}
</style>